<template>
  <div class="service-center">
    service-center
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ServiceCenter",
  components: {}
};
</script>
